// Import core libraries
import * as bip39 from "bip39";
import { BIP32Factory } from "bip32";
import * as ecc from "@bitcoinerlab/secp256k1";
import CryptoJS from "crypto-js";
import { v5 as uuidv5 } from "uuid";
import { split, combine } from "shamirs-secret-sharing";

// Initialize BIP32 with secure elliptic curve implementation
const bip32 = BIP32Factory(ecc);

// BSV will be loaded conditionally
let bsv;
try {
  bsv = require("bsv");
} catch (e) {
  console.warn("BSV library not loaded in browser environment");
  bsv = null;
}

class StorageAdapter {
  constructor() {
    if (typeof localStorage !== "undefined") {
      this.storage = localStorage;
      this.type = "localStorage";
    } else {
      this.storage = new Map();
      this.type = "memoryStorage";
    }
  }

  setItem(key, value) {
    if (this.type === "localStorage") {
      this.storage.setItem(key, value);
    } else {
      this.storage.set(key, value);
    }
  }

  getItem(key) {
    if (this.type === "localStorage") {
      return this.storage.getItem(key);
    }
    return this.storage.get(key);
  }

  removeItem(key) {
    if (this.type === "localStorage") {
      this.storage.removeItem(key);
    } else {
      this.storage.delete(key);
    }
  }

  key(index) {
    if (this.type === "localStorage") {
      return this.storage.key(index);
    }
    return Array.from(this.storage.keys())[index];
  }

  get length() {
    if (this.type === "localStorage") {
      return this.storage.length;
    }
    return this.storage.size;
  }
}

class SmartLedger {
  constructor() {
    this.bsv = bsv;
    this.bip32 = bip32;
    this.storage = new StorageAdapter();
    this.prefix = "smartledger_";

    // Bind all methods to instance
    // BIP39/32 Methods
    this.generateMnemonic = this.generateMnemonic.bind(this);
    this.validateMnemonic = this.validateMnemonic.bind(this);
    this.mnemonicToSeed = this.mnemonicToSeed.bind(this);
    this.mnemonicToSeedHex = this.mnemonicToSeedHex.bind(this);
    this.derivePath = this.derivePath.bind(this);
    this.deriveStandardPaths = this.deriveStandardPaths.bind(this);

    // BSV Operations
    this.getAddressFromNode = this.getAddressFromNode.bind(this);
    this.generateKeyPair = this.generateKeyPair.bind(this);
    this.getPublicKey = this.getPublicKey.bind(this);
    this.signMessage = this.signMessage.bind(this);
    this.verifySignature = this.verifySignature.bind(this);

    // Cryptographic Operations
    this.encrypt = this.encrypt.bind(this);
    this.decrypt = this.decrypt.bind(this);
    this.hash = this.hash.bind(this);
    this.splitSecret = this.splitSecret.bind(this);
    this.combineShares = this.combineShares.bind(this);

    // Storage Operations
    this.storeKey = this.storeKey.bind(this);
    this.retrieveKey = this.retrieveKey.bind(this);
    this.listKeys = this.listKeys.bind(this);
    this.removeKey = this.removeKey.bind(this);
    this.storeMnemonic = this.storeMnemonic.bind(this);
    this.retrieveMnemonic = this.retrieveMnemonic.bind(this);

    // Utility Functions
    this.generateUUID = this.generateUUID.bind(this);
    this.isValidPrivateKey = this.isValidPrivateKey.bind(this);
    this.isValidPublicKey = this.isValidPublicKey.bind(this);
    this.getRandomBytes = this.getRandomBytes.bind(this);
    this.base64Encode = this.base64Encode.bind(this);
    this.base64Decode = this.base64Decode.bind(this);
  }
  // BIP39 Methods
  generateMnemonic(strength = 256) {
    try {
      if (![128, 256].includes(strength)) {
        throw new Error(
          "Strength must be either 128 (12 words) or 256 (24 words)"
        );
      }
      return bip39.generateMnemonic(strength);
    } catch (error) {
      throw new Error(`Mnemonic generation failed: ${error.message}`);
    }
  }

  validateMnemonic(mnemonic) {
    try {
      if (!mnemonic || typeof mnemonic !== "string") {
        throw new Error("Mnemonic must be a non-empty string");
      }
      return bip39.validateMnemonic(mnemonic);
    } catch (error) {
      throw new Error(`Mnemonic validation failed: ${error.message}`);
    }
  }

  async mnemonicToSeed(mnemonic, passphrase = "") {
    try {
      if (!this.validateMnemonic(mnemonic)) {
        throw new Error("Invalid mnemonic");
      }
      return await bip39.mnemonicToSeed(mnemonic, passphrase);
    } catch (error) {
      throw new Error(`Seed generation failed: ${error.message}`);
    }
  }

  async mnemonicToSeedHex(mnemonic, passphrase = "") {
    try {
      const seed = await this.mnemonicToSeed(mnemonic, passphrase);
      return seed.toString("hex");
    } catch (error) {
      throw new Error(`Seed hex generation failed: ${error.message}`);
    }
  }

  // BIP32 HD Wallet Methods
  async derivePath(mnemonic, path, passphrase = "") {
    try {
      const seed = await this.mnemonicToSeed(mnemonic, passphrase);
      const root = this.bip32.fromSeed(Buffer.from(seed));
      const derived = root.derivePath(path);

      // Convert public key to proper BSV-compatible format
      const pubKeyBuffer = Buffer.from(derived.publicKey);
      const pubKeyHex = '0' + (pubKeyBuffer[0] === 2 ? '2' : '3') + pubKeyBuffer.slice(1).toString('hex');

      return {
        path,
        privateKey: derived.privateKey?.toString("hex"),
        publicKey: pubKeyHex,
        wif: derived.toWIF(),
        chainCode: derived.chainCode.toString("hex"),
        index: derived.index,
        depth: derived.depth,
      };
    } catch (error) {
      throw new Error(`Path derivation failed: ${error.message}`);
    }
  }

  async deriveStandardPaths(mnemonic, passphrase = "") {
    try {
      const paths = [];
      const purpose = 44; // BIP44
      const coinType = 236; // BSV

      // Generate main paths
      for (let account = 0; account < 3; account++) {
        for (let change = 0; change < 2; change++) {
          for (let index = 0; index < 3; index++) {
            const path = `m/${purpose}'/${coinType}'/${account}'/${change}/${index}`;
            const derived = await this.derivePath(mnemonic, path, passphrase);
            paths.push(derived);
          }
        }
      }

      // Add custom paths
      const customPaths = [
        `m/${purpose}'/${coinType}'/0'/0/0`,
        `m/${purpose}'/${coinType}'/1'/0/0`,
        `m/${purpose}'/${coinType}'/2'/0/0`,
      ];

      for (const path of customPaths) {
        const derived = await this.derivePath(mnemonic, path, passphrase);
        paths.push(derived);
      }

      return paths;
    } catch (error) {
      throw new Error(`Standard paths derivation failed: ${error.message}`);
    }
  }

  // BSV Operations
  getAddressFromNode(node) {
    try {
      if (!this.bsv) throw new Error("BSV library not available");
      return this.bsv.PublicKey.fromString(node.publicKey.toString("hex"))
        .toAddress()
        .toString();
    } catch (error) {
      throw new Error(`Address generation failed: ${error.message}`);
    }
  }

  generateKeyPair() {
    if (!this.bsv) throw new Error("BSV library not available");
    try {
      const privateKey = this.bsv.PrivateKey.fromRandom();
      const publicKey = privateKey.publicKey;
      return { privateKey, publicKey };
    } catch (error) {
      throw new Error(`Key pair generation failed: ${error.message}`);
    }
  }

  getPublicKey(privateKey) {
    if (!this.bsv) throw new Error("BSV library not available");
    try {
      return this.bsv.PrivateKey.fromString(privateKey).publicKey;
    } catch (error) {
      throw new Error(`Public key derivation failed: ${error.message}`);
    }
  }

  async signMessage(message, privateKeyOrPath, mnemonic = null, passphrase = "") {
    if (!this.bsv) throw new Error("BSV library not available");
    try {
      let privKey;
      
      if (mnemonic && privateKeyOrPath.startsWith('m/')) {
        // Handle HD wallet path
        const derived = await this.derivePath(mnemonic, privateKeyOrPath, passphrase);
        if (!derived.wif) {
          throw new Error("Failed to derive private key WIF");
        }
        privKey = this.bsv.PrivateKey.fromWIF(derived.wif);
      } else {
        // Handle direct private key (WIF)
        privKey = this.bsv.PrivateKey.fromWIF(privateKeyOrPath);
      }

      const messageHash = this.bsv.crypto.Hash.sha256(Buffer.from(message));
      const signature = this.bsv.crypto.ECDSA.sign(messageHash, privKey);
      return signature.toString('hex'); // Convert to hex string
    } catch (error) {
      throw new Error(`Message signing failed: ${error.message}`);
    }
  }

  async verifySignature(message, signature, publicKeyOrPath, mnemonic = null, passphrase = "") {
    if (!this.bsv) throw new Error("BSV library not available");
    try {
      let pubKey;
      
      if (mnemonic && publicKeyOrPath.startsWith('m/')) {
        // Handle HD wallet path
        const derived = await this.derivePath(mnemonic, publicKeyOrPath, passphrase);
        if (!derived.publicKey) {
          throw new Error("Failed to derive public key");
        }
        pubKey = this.bsv.PublicKey.fromString(derived.publicKey);
      } else {
        // Handle direct public key
        pubKey = this.bsv.PublicKey.fromString(publicKeyOrPath);
      }

      const messageHash = this.bsv.crypto.Hash.sha256(Buffer.from(message));
      // Convert hex signature back to Buffer
      const sigBuffer = Buffer.from(signature, 'hex');
      const sig = this.bsv.crypto.Signature.fromString(sigBuffer);
      return this.bsv.crypto.ECDSA.verify(messageHash, sig, pubKey);
    } catch (error) {
      throw new Error(`Signature verification failed: ${error.message}`);
    }
  }

  // Cryptographic Operations
  hash(data, algorithm = "SHA256") {
    if (!data) throw new Error("Data is required for hashing");
    try {
      switch (algorithm.toUpperCase()) {
        case "SHA256":
          return CryptoJS.SHA256(data).toString();
        case "SHA512":
          return CryptoJS.SHA512(data).toString();
        case "MD5":
          return CryptoJS.MD5(data).toString();
        default:
          throw new Error(`Unsupported hash algorithm: ${algorithm}`);
      }
    } catch (error) {
      throw new Error(`Hashing failed: ${error.message}`);
    }
  }

  // AES Encryption
  encrypt(data, key) {
    if (!data) throw new Error("Data is required for encryption");
    if (!key) throw new Error("Key is required for encryption");
    try {
      const options = { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 };
      return CryptoJS.AES.encrypt(data, key, options).toString();
    } catch (error) {
      throw new Error(`Encryption failed: ${error.message}`);
    }
  }

  decrypt(encryptedData, key) {
    if (!encryptedData) throw new Error("Encrypted data is required");
    if (!key) throw new Error("Key is required for decryption");
    try {
      const options = { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 };
      const bytes = CryptoJS.AES.decrypt(encryptedData, key, options);
      const decrypted = bytes.toString(CryptoJS.enc.Utf8);
      if (!decrypted) throw new Error("Decryption produced empty result");
      return decrypted;
    } catch (error) {
      throw new Error(`Decryption failed: ${error.message}`);
    }
  }

  // Shamir Secret Sharing
  splitSecret(secret, numShares, threshold) {
    try {
      if (typeof secret !== "string") {
        throw new Error("Secret must be a string");
      }
      if (!Number.isInteger(numShares) || numShares < 2) {
        throw new Error("Number of shares must be an integer greater than 1");
      }
      if (
        !Number.isInteger(threshold) ||
        threshold < 2 ||
        threshold > numShares
      ) {
        throw new Error("Threshold must be between 2 and number of shares");
      }

      const secretBytes = new TextEncoder().encode(secret);
      const shares = split(secretBytes, { shares: numShares, threshold });

      return shares.map((share) =>
        Array.from(share)
          .map((b) => b.toString(16).padStart(2, "0"))
          .join("")
      );
    } catch (error) {
      throw new Error(`Secret splitting failed: ${error.message}`);
    }
  }

  combineShares(shares) {
    try {
      if (!Array.isArray(shares)) throw new Error("Shares must be an array");
      if (shares.length < 2) throw new Error("At least 2 shares required");

      const shareBytes = shares.map((share) => {
        const bytes = new Uint8Array(share.length / 2);
        for (let i = 0; i < share.length; i += 2) {
          bytes[i / 2] = parseInt(share.slice(i, i + 2), 16);
        }
        return bytes;
      });

      const reconstructed = combine(shareBytes);
      return new TextDecoder().decode(reconstructed);
    } catch (error) {
      throw new Error(`Share combination failed: ${error.message}`);
    }
  }

  // Utility Functions
  generateUUID(name, namespace = uuidv5.URL) {
    if (!name) throw new Error("Name is required for UUID generation");
    try {
      return uuidv5(name, namespace);
    } catch (error) {
      throw new Error(`UUID generation failed: ${error.message}`);
    }
  }

  isValidPrivateKey(privateKey) {
    if (!this.bsv) return false;
    try {
      this.bsv.PrivateKey.fromString(privateKey);
      return true;
    } catch {
      return false;
    }
  }

  isValidPublicKey(publicKey) {
    if (!this.bsv) return false;
    try {
      this.bsv.PublicKey.fromString(publicKey);
      return true;
    } catch {
      return false;
    }
  }

  getRandomBytes(length = 32) {
    if (!Number.isInteger(length) || length < 1) {
      throw new Error("Length must be a positive integer");
    }
    try {
      return CryptoJS.lib.WordArray.random(length);
    } catch (error) {
      throw new Error(`Random bytes generation failed: ${error.message}`);
    }
  }

  base64Encode(data) {
    if (!data) throw new Error("Data is required for base64 encoding");
    try {
      return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data));
    } catch (error) {
      throw new Error(`Base64 encoding failed: ${error.message}`);
    }
  }

  base64Decode(data) {
    if (!data) throw new Error("Data is required for base64 decoding");
    try {
      return CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(data));
    } catch (error) {
      throw new Error(`Base64 decoding failed: ${error.message}`);
    }
  }
  // Store encrypted key with metadata
  storeKey(id, key, metadata = {}, storageKey) {
    try {
      if (!id || !key) throw new Error("ID and key are required");
      if (!storageKey)
        throw new Error("Storage key is required for encryption");

      const encryptedKey = this.encrypt(key, storageKey);
      const data = {
        encrypted: encryptedKey,
        metadata: {
          ...metadata,
          timestamp: Date.now(),
          type: "key",
        },
      };

      const storageId = `${this.prefix}key_${id}`;
      this.storage.setItem(storageId, JSON.stringify(data));
      return true;
    } catch (error) {
      throw new Error(`Key storage failed: ${error.message}`);
    }
  }

  // Retrieve and decrypt key
  retrieveKey(id, storageKey) {
    try {
      if (!id) throw new Error("ID is required");
      if (!storageKey)
        throw new Error("Storage key is required for decryption");

      const storageId = `${this.prefix}key_${id}`;
      const data = this.storage.getItem(storageId);
      if (!data) throw new Error("Key not found");

      const parsed = JSON.parse(data);
      return {
        key: this.decrypt(parsed.encrypted, storageKey),
        metadata: parsed.metadata,
      };
    } catch (error) {
      throw new Error(`Key retrieval failed: ${error.message}`);
    }
  }

  // Store encrypted mnemonic
  storeMnemonic(id, mnemonic, metadata = {}, storageKey) {
    try {
      if (!this.validateMnemonic(mnemonic)) {
        throw new Error("Invalid mnemonic");
      }

      const encryptedMnemonic = this.encrypt(mnemonic, storageKey);
      const data = {
        encrypted: encryptedMnemonic,
        metadata: {
          ...metadata,
          timestamp: Date.now(),
          type: "mnemonic",
        },
      };

      const storageId = `${this.prefix}mnemonic_${id}`;
      this.storage.setItem(storageId, JSON.stringify(data));
      return true;
    } catch (error) {
      throw new Error(`Mnemonic storage failed: ${error.message}`);
    }
  }

  // Retrieve and decrypt mnemonic
  retrieveMnemonic(id, storageKey) {
    try {
      const storageId = `${this.prefix}mnemonic_${id}`;
      const data = this.storage.getItem(storageId);
      if (!data) throw new Error("Mnemonic not found");

      const parsed = JSON.parse(data);
      const mnemonic = this.decrypt(parsed.encrypted, storageKey);

      if (!this.validateMnemonic(mnemonic)) {
        throw new Error("Retrieved mnemonic is invalid");
      }

      return {
        mnemonic,
        metadata: parsed.metadata,
      };
    } catch (error) {
      throw new Error(`Mnemonic retrieval failed: ${error.message}`);
    }
  }

  // List all stored keys and mnemonics
  listKeys() {
    try {
      const keys = [];
      for (let i = 0; i < this.storage.length; i++) {
        const key = this.storage.key(i);
        if (key?.startsWith(this.prefix)) {
          const data = JSON.parse(this.storage.getItem(key));
          keys.push({
            id: key.replace(this.prefix, ""),
            type: data.metadata.type,
            timestamp: data.metadata.timestamp,
            metadata: data.metadata,
          });
        }
      }
      return keys;
    } catch (error) {
      throw new Error(`Listing keys failed: ${error.message}`);
    }
  }

  // Remove stored key or mnemonic
  removeKey(id) {
    try {
      const storageId = `${this.prefix}${id}`;
      this.storage.removeItem(storageId);
      return true;
    } catch (error) {
      throw new Error(`Key removal failed: ${error.message}`);
    }
  }
}

// Create singleton instance
const instance = new SmartLedger();

// Export as both module and global object
if (typeof window !== "undefined") {
  window.SmartLedger = instance;
}

export default instance;
